import React from "react";
import { Link } from "react-router-dom";
import ServiceLogo from "../../Images/ignition.svg";
import Plus from "../../Images/plus.svg";
import A1 from "../../Images/innovation1.jpg";
import { Parallax } from "react-parallax";
import "./style.scss";

const insideStyles = {
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

function InnovationHome(props) {
  return (
    <>
      <div className="creation-home innovation-home">
        <div className="container">
          <div className="d-flex" style={{ justifyContent: "flex-end" }}>
            <div className="text align-basline" style={{}}>
              <div className="sec-title" data-aos="fade-right">
                <a href="/innovation">
                  Innov<span>a</span>t<br />
                  ion
                </a>
              </div>
            </div>
            <div className="px-img" style={{ width: 500 }}>
              <div className="bg-sec">
                <a href="/innovation">
                  <Parallax bgImage={A1} strength={-100}>
                    <div className="bg-img">
                      <div style={insideStyles}>
                        <img src={ServiceLogo} alt="" />
                      </div>
                    </div>
                  </Parallax>
                </a>
              </div>
            </div>
          </div>
          <div className="d-flex" style={{ justifyContent: "flex-end" }}>
            <div className="plus" style={{ width: 500 }}>
              <a href="/innovation">
                <img
                  decoding="async"
                  src={Plus}
                  className=""
                  alt=""
                  loading="lazy"
                />

                <svg
                  id="heart"
                  width="110"
                  height="110"
                  viewBox="0 0 110 110"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    id="heart-path"
                    x1="55.5"
                    y1="2.18557e-08"
                    x2="55.5"
                    y2="110"
                    stroke="#B9B9B9"
                  ></line>
                  <line
                    id="heart-path"
                    x1="110"
                    y1="55.5"
                    x2="-4.37114e-08"
                    y2="55.5"
                    stroke="#B9B9B9"
                  ></line>
                </svg>
              </a>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-lg-6 align-basline">
              <div className="sec-title" data-aos="fade-right">
                <a href="/innovation">
                  Innov<span>a</span>t<br />
                  ion
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="bg-sec">
                <a href="/innovation">
                  <Parallax bgImage={A1} strength={-100}>
                    <div className="bg-img">
                      <div style={insideStyles}>
                        <img src={ServiceLogo} alt="" />
                      </div>
                    </div>
                  </Parallax>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6"></div>
            <div className="col-lg-6">
              <div className="plus">
                <a href="/innovation">
                  <img
                    decoding="async"
                    src={Plus}
                    className=""
                    alt=""
                    loading="lazy"
                  />

                  <svg
                    id="heart"
                    width="110"
                    height="110"
                    viewBox="0 0 110 110"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      id="heart-path"
                      x1="55.5"
                      y1="2.18557e-08"
                      x2="55.5"
                      y2="110"
                      stroke="#B9B9B9"
                    ></line>
                    <line
                      id="heart-path"
                      x1="110"
                      y1="55.5"
                      x2="-4.37114e-08"
                      y2="55.5"
                      stroke="#B9B9B9"
                    ></line>
                  </svg>
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default InnovationHome;
