import React from "react";
import "./style.scss";

function TouchCircle() {
  return (
    <>
      <a href="https://calendly.com/onehumun/30min" target="blank">
        <div className="touch-circle">
          <div className="inner-div">
            <small data-aos="zoom-in">get in</small>
            <span data-aos="zoom-in">touch!</span>
          </div>
        </div>
      </a>
    </>
  );
}

export default TouchCircle;
