import React, {
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
  useLayoutEffect,
  useState,
} from "react";
import { gsap, Bounce } from "gsap";
import { TweenLite, TimelineLite } from "gsap/all";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./Components/Footer";
import Home from "./Pages/Home";
import About from "./Pages/About";
import HorizontalThree from "./Components/OurTeam";
import Scroll from "./Components/Scrollbar";
import Services from "./Pages/Services";
import Faqs from "./Pages/Faqs";
import CreationInnerPage from "./Pages/Creation";
import AugmentationInnerPage from "./Pages/Augmentation";
import InnovationInnerPage from "./Pages/Innovation";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./Components/ScrollToTop/ScrollToTop";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./App.scss";
import Header from "./Components/Header";
import scrollImg from "./Images/scrollar_arrow.svg";

const Circle = forwardRef(({ size, delay }, ref) => {
  const el = useRef();

  useImperativeHandle(
    ref,
    () => {
      // return our API
      return {
        moveTo(x, y) {
          gsap.to(el.current, { x, y, delay });
        },
      };
    },
    [delay]
  );

  return <div className={"mouse-circle"} ref={el}></div>;
});

const BounceCircle = forwardRef(({ size, delay }, ref) => {
  const el = useRef();

  useImperativeHandle(
    ref,
    () => {
      // return our API
      return {
        moveTo(x, y) {
          gsap.to(el.current, { x, y, delay });
        },
      };
    },
    [delay]
  );

  return <div className={"mouse-circle bounce-circle"} ref={el}></div>;
});

function App() {
  AOS.init({
    offset: 200,
    duration: 600,
    easing: "ease-in-out-sine",
    delay: 100,
    once: 1,
  });
  AOS.refresh();

  const circleRefs = useRef([]);
  const showScrollCircle = useRef(true);
  const circleBounceRefs = useRef([]);
  const [showCircle, setShowCircle] = useState(false);

  const el = useRef();

  // const q = gsap.utils.selector(el);
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

  // useEffect(() => {
  //   var timer;
  //   var stoppedElement = document.getElementById("ball-box-wrapper"); // store element for faster access

  //   function mouseStopped() {
  //     // the actual function that is called
  //     stoppedElement.innerHTML = "Mouse stopped";
  //   }

  //   window.addEventListener("mousemove", function () {
  //     stoppedElement.innerHTML = "Mouse moving";

  //   });
  // }, [])

  useEffect(() => {
    gsap.to(".mytext", {
      scrollTrigger: {
        trigger: "body",
        scrub: 1,
        start: "start end",
        // end: "+=5000",
      },
      rotation: 3000,
      duration: 2,
      ease: "none",
    });
  }, []);

  // useEffect(() => {
  //   // gsap.to(".rotating-bubbles");
  //   gsap.to(".rotating-bubbles", {
  //     scrollTrigger: {
  //       // trigger: "body",
  //       scrub: 1,
  //       start: "center center",
  //       end: "+=5000",
  //     },
  //     rotation: 3000,
  //     duration: 2,
  //     ease: "none",
  //   });
  // }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: "#footer-widgets-section",
      scrub: 1,
      start: "-500px",
      onEnter: () => {
        setShowCircle(true);
        // dropBall();
      },
      onLeave: () => {
        setShowCircle(false);
        // repostionBall();
      },
      onEnterBack: () => {
        setShowCircle(true);
        // dropBall();
      },
      onLeaveBack: () => {
        setShowCircle(false);
        // repostionBall();
      },
    });
  }, []);

  useLayoutEffect(() => {
    let smoother = ScrollSmoother.create({
      smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
      effects: true, // looks for data-speed and data-lag attributes on elements
    });
    return () => {
      smoother.kill();
    };
  }, []);

  // reset on re-renders
  circleRefs.current = [];

  useEffect(() => {
    const { innerWidth, innerHeight } = window;
    circleRefs.current.forEach((ref) =>
      ref.moveTo(innerWidth / 5, innerHeight / 5)
    );

    const onMove = ({ clientX, clientY }) => {
      circleRefs.current.forEach((ref) => ref.moveTo(clientX, clientY));
    };

    window.addEventListener("pointermove", onMove);

    // return () => window.removeEventListener("pointermove", onMove);
  }, []);

  useEffect(() => {
    const { innerWidth, innerHeight } = window;
    circleBounceRefs.current.forEach((ref) =>
      ref.moveTo(innerWidth / 5, innerHeight / 5)
    );

    const onBounceMove = ({ clientX, clientY, screenX, screenY }) => {
      circleBounceRefs.current.forEach((ref) =>
        ref.moveTo(clientX + 80, clientY + 80)
      );

      // setTimeout(() => {
      //   circleBounceRefs.current.forEach((ref) =>
      //     ref.moveTo(screenX + 200, screenY + 200)
      //   );
      // }, 3000);
    };

    // const onMoveStop = ({ clientX, clientY }) => {
    //   circleBounceRefs.current.forEach((ref) =>
    //     ref.moveTo(clientX + 200, clientY + 200)
    //   );
    // };

    // window.addEventListener("pointermove", onBounceMove);
    // window.addEventListener("pointerover", onMoveStop);

    return () => window.removeEventListener("pointermove", onBounceMove);
  }, []);

  // const addCircleBounceRef = (ref) => {
  //   if (ref) {
  //     circleBounceRefs.current.push(ref);
  //   }
  // };

  useEffect(() => {
    let isBallDroped = null;

    function dropBall() {
      const ballWrapper = document.getElementById("ball-box-wrapper");
      const ball = document.getElementById("ball");

      if (isBallDroped === true) {
        TweenLite.set(ball, {
          y: 0,
          x: 0,
        });
      }
      
      isBallDroped = false;

      const tl = new TimelineLite({ delay: 0 });
      tl.to(ball, 2, {
        y: ballWrapper.offsetHeight,
        ease: Bounce.easeOut,
      });
      document.getElementById("footer-section").style.backgroundColor =
        "transparent";
    }

    function repostionBall() {
      const ball = document.getElementById("ball");
      isBallDroped = true;
      TweenLite.set(ball, {
        y: 0,
        x: 0,
      });
    }

    ScrollTrigger.create({
      trigger: "#ball-box-wrapper",
      // onEnter: dropBall,
      // onEnterBack: dropBall,
      onLeave: repostionBall,
      onLeaveBack: repostionBall,
      start: "-500px",
    });

    window.onscroll = function () {
      let windowScroll = document.documentElement.scrollTop;
      let windowHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      let footerHeight = document.getElementById("footer-section").offsetHeight; // get the height of the footer element
      let maxScrollHeight = windowHeight - footerHeight; // calculate the maximum scroll height
      let scrollAmount = 0;

      if (windowScroll > maxScrollHeight + 100) {
        document.getElementById("scrollCircle").style.bottom = "-100px";
        document.getElementById("footer-section").style.backgroundColor =
          "#ffffff";

        dropBall();
      } else {
        // if (!showCircle) {
        //   console.log("repostion", showCircle);
        //   repostionBall();
        //   isBallDroped = true;
        // }

        document.getElementById("footer-section").style.backgroundColor =
          "#ffffff";
        document.getElementById("scrollCircle").style.bottom = "";
      }

      if (windowScroll > maxScrollHeight) {
        document.getElementById("scrollCircle").style.zIndex = -1;

        scrollAmount = 100; // set the scroll indicator to 100%
      } else {
        document.getElementById("scrollCircle").style.zIndex = 100;

        scrollAmount = (windowScroll / maxScrollHeight) * 100; // calculate the scroll amount as a percentage
      }

      document.getElementById("mybar").style.height = scrollAmount
        ? scrollAmount + "%"
        : `0%`; // set the height of the scroll indicator

      //For rotating text on scroll

      // gsap.to(".rotating-bubbles");
      // gsap.to(".mytext", {
      //   scrollTrigger: {
      //     trigger: ".about-info",
      //     scrub: 1,
      //     start: "center center",
      //     end: "+=5000000",
      //   },
      //   rotation: 3000,
      //   duration: 2,
      //   ease: "none",
      // });

      // var theta = (window.pageYOffset % Math.PI) + 200;
      // document.getElementById("mytext").style.transform =
      //   "rotate(" + theta + "rad)";
    };
  }, []);

  const addCircleRef = (ref) => {
    if (ref) {
      circleRefs.current.push(ref);
    }
  };

  const scrollWin = () => {
    window.scrollBy({
      top: window.innerHeight,
      behavior: "smooth",
    });
    // var theta = (document.documentElement.scrollTop % Math.PI) + 200;
    // document.getElementById("mytext").style.transform =
    //   "rotate(" + theta + "rad)";
  };

  const pathName = window?.location?.pathname;

  return (
    <>
      <div id="main-wrapper">
        {pathName === "/about" || pathName === "/services" ? (
          <div className="background-dark">
            <Header />
          </div>
        ) : (
          <Header />
        )}

        <div ref={el} id="smooth-wrapper">
          <div id="smooth-content">
            {/* <Scroll /> */}
            <Router>
              <ScrollToTop>
                <Routes>
                  <Route path="/" exact element={<Home />} />
                  <Route path="/about" exact element={<About />} />
                  <Route path="/services" exact element={<Services />} />
                  <Route path="/faqs" exact element={<Faqs />} />
                  <Route
                    path="/creation"
                    exact
                    element={<CreationInnerPage />}
                  />
                  <Route
                    path="/augmentation"
                    exact
                    element={<AugmentationInnerPage />}
                  />
                  <Route
                    path="/innovation"
                    exact
                    element={<InnovationInnerPage />}
                  />
                  <Route path="/dragable" exact element={<HorizontalThree />} />
                </Routes>
              </ScrollToTop>
              <Footer />
            </Router>
          </div>
        </div>
      </div>

      {window.innerWidth > 520 && (
        <Circle size="sm" ref={addCircleRef} delay={0.04} />
      )}

      {/* <Circle size="sm" ref={addCircleRef} delay={0.5} /> */}
      {/* {showCircle && (
        <BounceCircle size="sm" ref={addCircleBounceRef} delay={0.3} />
      )} */}

      <div
        id="scrollCircle"
        className="circular-text"
        onClick={() => scrollWin()}
      >
        <svg
          viewBox="0 0 100 100"
          width="100"
          height="100"
          id="mytext"
          className="mytext"
          // style={{ transform: "rotate(1.71681rad)" }}
        >
          <defs>
            <path
              id="circle"
              d="
        M 50, 50
        m -37, 0
        a 37,37 0 1,1 74,0
        a 37,37 0 1,1 -74,0"
            ></path>
          </defs>
          <text fontSize="10.8" fontWeight="500">
            <textPath xlinkHref="#circle">
              SCROLL TO EXPLORE • SCROLL TO EXPLORE •
            </textPath>
          </text>
        </svg>

        <div className="scroll-cirlce">
          <div className="progress-bar" id="mybar"></div>
          <img src={scrollImg} alt="Scroll Bar" />
        </div>
      </div>
    </>
  );
}

export default App;
