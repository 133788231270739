import React, { useEffect } from "react";
import OurTeamSection from "../OurTeam";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./style.scss";

function TeamMembers(props) {
  const addBounceClass = () => {
    const element = document.querySelector(".small-dot");
    element?.classList?.add("dot-bounce-in-down");
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.create({
      trigger: ".team-members-sec",
      start: "-500px",
      markers: false,
      onEnter: () => addBounceClass(),
    });
  }, []);
  return (
    <>
      <div className="team-members-sec" id="main-team-section">
        <div className="container">
          <div className="d-flex">
            <div className="title">
              OUr TE<span>a</span>M
            </div>
            <div
              className="small-dot"
              // data-aos="fade-down"
              // data-aos-easing="ease-in-back"
              // data-aos-delay="800"
              // data-aos-offset="0"
            />
          </div>
          <div className="des">
            With diverse backgrounds spreading across the seven continents of
            the world. Our human centric approach brings us together to provide
            impactful solutions. Tackling the world in unique and interesting
            ways.
          </div>
          <div className="our-team"></div>
        </div>
        <OurTeamSection />
      </div>
    </>
  );
}

export default TeamMembers;
