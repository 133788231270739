import React, { useEffect } from "react";
import { gsap } from "gsap";

function Face(props) {
  useEffect(() => {
    gsap.utils.toArray(".parallax-face").forEach((section, i) => {
      section.bg = section.querySelector(".parallax-bg1");

      //  section.bg.style.backgroundImage = `url("https://picsum.photos/1920?random=${i}")`;

      if (i) {
        // The image starts offset 50% of the vertical height. Remember, the background-attachment is 'fixed', so the offset is from the top of the viewport, not the top of the section.
        section.bg.style.backgroundPosition = `${window.innerHeight / 2}px 50%`;

        gsap.to(section.bg, {
          backgroundPosition: `${-window.innerHeight / 2} 50%`,
          ease: "none",
          scrollTrigger: {
            trigger: section,
            scrub: true,
            // Default values:
            // start: "top bottom", // top of elem meets bottom of screen
            // end: "bottom top" // Bottom of elem meets top of screen
          },
        });
      } else {
        section.bg.style.backgroundPosition = "0px 50%";

        gsap.to(section.bg, {
          backgroundPosition: `${-window.innerHeight / 2}px 50%`,
          ease: "none",
          scrollTrigger: {
            trigger: section,
            //  start: "top top", // top of elem meets top of screen (e.g., on load)
            // end: "bottom top", // This is the default
            scrub: true,
          },
        });
      }
    });
  }, []);
  return (
    <>
      <div className="parallax-face">
        <div className="parallax-bg1 face" />
      </div>
    </>
  );
}

export default Face;
