import React from "react";

const TeamBox = ({ data, index }) => {
  const mouseOverOnTeam = (i) => {
    const teamBox = document.querySelector(`#teamVideo_${i + 1}`);
    teamBox.pause();
    teamBox.currentTime = 0;
    teamBox.play();
  };

  const mouseOutOnTeam = (i) => {
    const teamBox = document.querySelector(`#teamVideo_${i + 1}`);
    teamBox.currentTime = 0;
    teamBox.pause();
  };

  return (
    <div
      className={window.innerWidth < 520 ? "team-box mobile-team" : "team-box"}
      onMouseOver={() => mouseOverOnTeam(index)}
      onMouseOut={() => mouseOutOnTeam(index)}
    >
      <div className="team-inner">
        <div className="top-box top-box-one" />
        <div className="top-box top-box-two" />
        <div>
          <div className="member-name" style={{ marginBottom: -90 }}>
            {data.name}
          </div>
          {/* <img src={data.image} alt={data.name} /> */}
          {/* <video
            id={`teamVideo_${index+1}`}
            src={data.video}
            muted="muted"
            loop="loop"
            onMouseOver={(event) => {
              event.target.play();
            }}
            onMouseOut={(event) => {
              event.target.currentTime = 0;
              event.target.pause();
            }}
            style={{ width: "100%" }}
            type="video/mp4"
          ></video> */}

          <video
            playsInline
            id={`teamVideo_${index + 1}`}
            muted="muted"
            loop="loop"
            controls={false}
            onMouseOver={(event) => {
              event.target.play();
            }}
            onMouseOut={(event) => {
              event.target.currentTime = 0;
              event.target.pause();
            }}
            style={{ width: "100%", padding: "90px 0" }}
          >
            <source src={`${data.video}#t=0.1`} type="video/mp4" />
            Your browser does not support HTML video.
          </video>

          <div className="member-designation" style={{ marginTop: -90 }}>
            {data.designation}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamBox;
