import React, { useEffect, useState } from "react";
import { Bounce, gsap, Power2 } from "gsap";
import { TimelineLite, TweenLite } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./style.scss";

gsap.registerPlugin(ScrollTrigger);

function Footer(props) {

  function playWithBall(clientX, clientY, direction) {
    const ball = document.getElementById("ball");
    const ballWrapper = document.getElementById("ball-box-wrapper");

    const tl = new TimelineLite({ delay: 0, repeat: 0 });

    tl.to(ball, 1, {
      // y: clientY,
      // x: clientX,
      // transform: `translate('${clientX}', '${clientY}')`,
      x: `${
        clientX -
        90 +
        ((direction === "left" && -180) || (direction === "right" && 180) || 0)
      }`,
      y: `${clientY}`,
      repeat: 0,
      delay: 0,
      duration: 300,
      // ease: Bounce.easeOut,
    }).to(ball, 2, {
      y: `${ballWrapper.clientHeight}`,
      // x: `${clientX}`,
      // x: clientX,
      repeat: 0,
      delay: -1,
      duration: -1,
      ease: Bounce.easeOut,
    });
  }

      let oldx = 0;


  const mouseMovingFunc = (event) => {
    const { clientX, clientY, pageX } = event;
    let direction = "";

    if (pageX < oldx) {
      direction = "left";
    } else if (pageX > oldx) {
      direction = "right";
    }

    oldx = pageX;
    playWithBall(clientX, clientY, direction);
  };

  return (
    <>
      <div
        id="ball-box-wrapper"
        onMouseMove={(event) => mouseMovingFunc(event)}
      >
        <span id="ball"></span>
        <div id="footer-section" className="footer">
          <div className="top-menu">
            <div className="container">
              <div className="row">
                <div className="col-3 p-0">
                  <a href="/">
                    <div className="logo">
                      <svg
                        width="50"
                        height="28"
                        viewBox="0 0 50 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12.2428 26.9231C9.81042 26.9231 7.67537 26.3905 5.8376 25.3253C3.99984 24.2334 2.56746 22.6756 1.54048 20.6517C0.513493 18.6278 0 16.1911 0 13.3417C0 10.4923 0.513493 8.08225 1.54048 6.11162C2.56746 4.11436 3.99984 2.59644 5.8376 1.55786C7.67537 0.519288 9.81042 0 12.2428 0C14.6751 0 16.8101 0.519288 18.6479 1.55786C20.4857 2.59644 21.918 4.11436 22.945 6.11162C23.972 8.08225 24.4855 10.4923 24.4855 13.3417C24.4855 16.1911 23.972 18.6278 22.945 20.6517C21.918 22.6756 20.4857 24.2334 18.6479 25.3253C16.8101 26.3905 14.6751 26.9231 12.2428 26.9231ZM12.2428 21.0112C13.2968 21.0112 14.2021 20.7049 14.9589 20.0924C15.7426 19.4533 16.3372 18.5612 16.7426 17.4161C17.148 16.271 17.3507 14.9129 17.3507 13.3417C17.3507 11.7705 17.148 10.439 16.7426 9.34718C16.3372 8.22871 15.7426 7.37655 14.9589 6.79069C14.2021 6.20482 13.2968 5.91189 12.2428 5.91189C11.1887 5.91189 10.2699 6.20482 9.48611 6.79069C8.72938 7.37655 8.14832 8.22871 7.74293 9.34718C7.33754 10.439 7.13485 11.7705 7.13485 13.3417C7.13485 14.9129 7.33754 16.271 7.74293 17.4161C8.14832 18.5612 8.72938 19.4533 9.48611 20.0924C10.2699 20.7049 11.1887 21.0112 12.2428 21.0112Z"
                          fill="black"
                        ></path>
                        <path
                          d="M28.1901 26.4437V0.479344H35.1628V10.1461H43.0273V0.479344H50V16.1379H43.0273H35.1628V26.4437H28.1901Z"
                          fill="black"
                        ></path>
                        <circle
                          className="circle-bounce"
                          cx="46.591"
                          cy="23.8637"
                          r="3.40909"
                          fill="#EA3335"
                        ></circle>
                      </svg>
                    </div>
                  </a>
                </div>
                <div className="col-9 p-0">
                  <div className="footer-menu">
                    <ul>
                      <li className="magnetic-wrap">
                        <div className="js-magnetic-area magnetic-size"></div>
                        <div className="js-magnetic-content">
                          <a href="/about">About</a>
                        </div>
                      </li>
                      <li className="magnetic-wrap">
                        <div className="js-magnetic-area magnetic-size"></div>
                        <div className="js-magnetic-content">
                          <a href="/services">Services</a>
                        </div>
                      </li>
                      <li className="magnetic-wrap">
                        <div className="js-magnetic-area magnetic-size"></div>
                        <div className="js-magnetic-content">
                          <a href="/faqs">Faqs</a>
                        </div>
                      </li>
                      {/* <li>
                    <Link to="/about">Contact</Link>
                  </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="footer-widgets-section" className="inner-widgets">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <div className="info">
                    <div className="heading">ONEHUMUN</div>
                    <div className="descripton">
                      Through our unique Embedded Partnership Approach and
                      intuitive AI Powered Matching Engine. We’ve delivered
                      organizational, board and advisory solutions for some of
                      the world's most exciting VC-backed companies across the
                      globe. Helping shape the future of work, life and play!
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info">
                    <div className="heading">OFFICE</div>
                    <div className="offices-list">
                      <div className="item">
                        <div className="head">APAC</div>
                        <div className="des">
                          234 Sussex, Street,
                          <br /> Sydney, AU
                          <div className="des">
                            <a href="tel:+612-9057-6408">+612-9057-6408</a>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="head">North America</div>
                        <div className="des">
                          101 Hudson St
                          <br />
                          Jersey City, NJ, US
                          <div className="des">
                            <a href="tel:+1-201-633-2300">+1-201-633-2300</a>
                          </div>
                        </div>
                      </div>
                      <div className="item">
                        <div className="head">EMEA</div>
                        <div className="des">
                          1 Primrose Street,
                          <br /> London, UK
                          <div className="des">
                            <a href="tel:+44-20-3695-7895">+44-20-3695-7895</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="info">
                    <div className="heading">Get in touch</div>
                    <div className="item">
                      <div className="link">
                        <a href="mailto:info@onehumun.com">info@onehumun.com</a>
                      </div>
                      {/* <div className="link">
                        <a href="tel:+61-481-291-271">+61-481-291-271</a>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="on-page">
            <div className="container">
              <a href="https://calendly.com/onehumun/30min" target="blank">
                LET'S TALK!
              </a>
            </div>
          </div>
          <div className="bottom-menu">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 p-0">
                  <div className="copy-rights">
                    <a href="/">ONEHUMANM © 2023</a>{" "}
                  </div>
                </div>
                <div className="col-lg-9 p-0">
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="/#">FACEBOOK</a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/oneworldly/about/"
                          target="blank"
                        >
                          LINKEDIN
                        </a>
                      </li>
                      <li>
                        <a href="/#">INSTAGRAM</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
