import React, { useEffect } from "react";
import Header from "../../Components/Header";
import AugmentationHome from "../../Components/AugmentationHome";
import CreationHome from "../../Components/CreationHome";
import HomeBanner from "../../Components/HomeBanner";
import InnovationHome from "../../Components/InnovationHome";

import "./style.scss";
import "./responsiveness.scss";

const Home = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#F9F9F9";
  }, []);

  return (
    <div id="home-page-wrapper">
      {/* <Header /> */}
      <HomeBanner />
      <CreationHome />
      <AugmentationHome />
      <InnovationHome />
    </div>
  );
};

export default Home;
