import React, { useEffect } from "react";
import Header from "../../Components/Header";
import Logo from "../../Images/ignition.png";
import { Parallax } from "react-parallax";
import A1 from "../../Images/innovation.jpg";
import "./style.scss";

const insideStyles = {
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

function InnovationInnerPage(props) {
  useEffect(() => {
    document.body.style.backgroundColor = "#fff";
  }, []);
  return (
    <>
      {/* <Header /> */}
      <div className="mar-top">
        <div className="container">
          <div className="page-heading">
            Innov<span>a</span>t<br />
            ion
          </div>
          <div className="make-flex">
            <div className="des">
              <div className="lab">
                An advisory and consulting engagement for a new market expansion
                strategy. This was after Ignition closed $50m in a series C
                round to accelerate global expansion.
              </div>
              <div className="listing">
                <div className="item">
                  Creation and marketing of EVP / Branding assets to penetrate
                  new strategic markets.
                </div>
                <div className="item">
                  Appointed the entire executive team through One Humun’s AI
                  Matching Engine.
                </div>
                <div className="item">
                  Automation of hiring and onboarding of remote augmented teams.
                </div>
              </div>
            </div>
            <div className="parallax_bg">
              <Parallax bgImage={A1} strength={-50}>
                <div className="bg-img">
                  <div style={insideStyles}>
                    <img src={Logo} alt="" />
                  </div>
                </div>
              </Parallax>
            </div>
          </div>
          <div className="info">
            <div className="head">
              Guy Pe<span>a</span>rson - Co-Founder <label>and CEO</label>
            </div>
            <div className="des">
              “As a Founder, expanding your business globally can be a daunting
              task. Our main driver for working with the team at ONE was their
              ability to execute a launch strategy for us and tap into their
              already established global networks. This meant we had strong
              brand perception before we were even on the ground. We were then
              able to build a really solid foundational team very fast which
              till this day, was critical to our success”
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InnovationInnerPage;
