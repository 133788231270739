import Scrollbar from "smooth-scrollbar";
import { useEffect } from "react";
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll";

const overscrollOptions = {
  enable: true,
  effect: "bounce",
  damping: 0.15,
  maxOverscroll: 150,
  glowColor: "#fff",
};

const options = {
  damping: 0.07,
  plugins: {
    overscroll: { ...overscrollOptions },
  },
};

const Scroll = () => {
  useEffect(() => {
    const element = document.body;
    if (element) {
      Scrollbar.use(OverscrollPlugin);
      Scrollbar.init(element, options);

      return () => {
        if (Scrollbar)
          Scrollbar.destroy(element);
      };
    }
  }, []);

  return null;
};

export default Scroll;
