import React, { useEffect, useState } from "react";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  MoveOut,
  batch,
} from "react-scroll-motion";
import "./style.scss";

function ServicesBanner(props) {
  const [height] = useState(window.innerHeight);

  return (
    <>
      <div className="services-banner" style={{ height: height }}>
        <ScrollContainer>
          <ScrollPage className="scroll-page" style={{ height: height }}>
            <Animator
              className="scrolling-text"
              animation={
                window.innerWidth < 520 ? MoveOut(-700, 0) : MoveOut(-1600, 0)
              }
            >
              OUR SERVICES
            </Animator>
          </ScrollPage>
        </ScrollContainer>
        <div className="scrolling-text"></div>
      </div>
    </>
  );
}

export default ServicesBanner;
