import React, { useEffect } from "react";
import Header from "../../Components/Header";
import FaqBanneText from "../../Images/faq-banner-text.svg";
import { MouseParallax, ScrollParallax } from "react-just-parallax";
import EyeImage from "../../Images/faqs_banner.jpg";
import { Accordion } from "react-bootstrap";
import "./style.scss";
import "./responsiveness.scss";
import FaqArrow from "../../Components/FaqArrow";

function Faqs() {
  useEffect(() => {
    document.body.style.backgroundColor = "#fff";
    // const mainElement = document.querySelector("body");

    // const el = document.querySelector("#faq-main-img");

    // mainElement.addEventListener("pointermove", (e) => {
    //   el.style.backgroundPositionX = -e.clientX / 20 + "px";
    //   el.style.backgroundPositionY = -e.clientY / 20 + "px";
    // });

    // mainElement.addEventListener("mouse", (e) => {
    //   el.style.backgroundPositionX = e.clientX / 20 + "px";
    //   el.style.backgroundPositionY = e.clientY / 20 + "px";
    // });

    // el.addEventListener("mousemove", (e) => {
    //   el.style.backgroundPositionX = -e.offsetX / 40 + "px";
    //   el.style.backgroundPositionY = -e.offsetY / 40 + "px";
    // });
  }, []);
  return (
    <>
      {/* <Header /> */}
      <div className="faqs-banner">
        <div className="container">
          {/* <div className="eye-image">
            <div className="faq-image" id="faq-main-img"></div>
          </div> */}
          <div className="eye-image">
            <MouseParallax
              enableOnTouchDevice
              isAbsolutelyPositioned
              strength={0.02}
            >
              <div className="faq-image"></div>
              {/* <img src={EyeImage} alt="" /> */}
            </MouseParallax>
          </div>
          <div
            className="heading"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0"
          >
            <img src={FaqBanneText} alt="" />
          </div>
        </div>
      </div>
      <div className="faqs-sec" data-aos="fade-up">
        <div className="container">
          <Accordion
          // defaultActiveKey="0"
          >
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                Do you work with SMEs or enterprises?
                <FaqArrow />
              </Accordion.Header>
              <Accordion.Body>
                Both! We’ve partnered with some of Silicon’s Valley’s most
                exciting companies. We are now offering our services to the next
                generation of start-ups. If you share the same spirit of being
                bold, ambitious and are genuinely creating change - we’d love to
                talk. We like to be part of that journey and are in it for the
                long haul!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Wh<span>a</span>t sectors do you speci<span>a</span>lize in?
                <FaqArrow />
              </Accordion.Header>
              <Accordion.Body>
                We are firmly rooted in emerging tech, renewable energy, gaming,
                pharma and life sciences. One of our key differences is that
                prior to inception. We spent years prior building our
                proprietary intuitive AI technology and growth models that can
                be embedded to any organization across any sector.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                In which countries do you oper<span>a</span>te?
                <FaqArrow />
              </Accordion.Header>
              <Accordion.Body>
                We work across APAC, EMEA and the Americas. We see the world as
                one ‘global village’ and are on a serious mission to embrace a
                boundaryless economy. We created our business as we recognized
                great talent existed in regions where people never expected.
                Organizations were creating a bidding war over a very small pool
                of local talent and continuously scrambling. Our goal was to
                make it easy for organizations to connect, hire and experience
                talented humans across the world while creating opportunities
                for people in those regions that never existed. It’s a win-win
                for everyone involved!
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                C<span>a</span>n you help us sc<span>a</span>le virtu
                <span>a</span>lly?
                <FaqArrow />
              </Accordion.Header>
              <Accordion.Body>
                Absolutely! We offer ‘modular’ teams for growth so that you can
                hit the ground running wherever, whenever you need; we embed a
                holistic approach when partnering to ensure we’re up to speed
                with your purpose, vision, deliverables and culture.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                HOW QUICKLY C<span>a</span>N YOU EMBED YOUR TE
                <span>a</span>MS, PRODUCTS <span>a</span>ND SERVICES?
                <FaqArrow />
              </Accordion.Header>
              <Accordion.Body>
                Once you are onboarded with ONE, this can be set up for you
                within ONE working day.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                WH<span>a</span>T IS YOUR FEE STRUCTURE?
                <FaqArrow />
              </Accordion.Header>
              <Accordion.Body>
                Flexibility is at our core. We understand the different stages
                of growth so have various engagement models that scale with your
                business. Whether it be retainers, subscriptions, fixed pricing
                or shared risk models. We will work closely with you and put a
                structure in place that makes sense.
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </>
  );
}

export default Faqs;
