import React from "react";
import C1 from "../../Images/about-banner-m.jpg";
import { Parallax } from "react-parallax";
import "./style.scss";

const insideStyles = {
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

function AboutBanner() {
  return (
    <>
      <div
        className="about-banner"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-delay="300"
        data-aos-offset="0"
      >
        <div className="container">
          <div className="heading">
            Introducing <br />
            <label>ONE humun.</label>
          </div>
          <div className="parallax-wrapper">
            <div className="parallax-bg about-main-bg" />
          </div>
          {/* <div className="parallex-bg"></div> */}
          {/* <div className="bg-sec">
            <div className="parallax-wrapper">
              <div className="parallax-bg about-banner-bg" />
            </div>

          </div> */}
          <div className="bg-sec">
            <div className="m-view">
              <Parallax bgImage={C1} strength={-100}>
                <div className="bg-img">
                  <div style={insideStyles}></div>
                </div>
              </Parallax>
            </div>
          </div>
        </div>
        {/* <div className="arrow-bottom">
          <svg
            width="116"
            height="115"
            viewBox="0 0 116 115"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="58.5"
              y1="2.18557e-08"
              x2="58.5"
              y2="114"
              stroke="white"
            />
            <path d="M1 57L58 114" stroke="white" />
            <path d="M115 57L58 114" stroke="white" />
          </svg>
        </div> */}
      </div>
      <div className="about-info">
        <div className="container">
          <div className="make-flex">
            <div className="heading">
              ONE HUMUN w<span>a</span>s born to cre<span>a</span>te{" "}
              <span>a</span> future proof model for growth,{" "}
              <label>
                empowering org
                <span>a</span>niz<span>a</span>tions to <span>a</span>cceler
                <span>a</span>te their <span>a</span>bility to sc<span>a</span>
                le <span>a</span>nd creating opportunities
              </label>{" "}
              for hum<span>a</span>ns that never existed.
            </div>

            <div className="description">
              <div
                className="item"
                // data-aos="fade-in"
                // data-aos-offset="300"
                // data-aos-easing="ease-in-sine"
              >
                Through our unique Embedded Partnership Approach and intuitive
                AI Powered Matching Engine. We have eliminated the typical
                challenges organizations face when scaling.
              </div>
              {/* <div
                className="item"
                // data-aos="fade-in"
                // data-aos-offset="300"
                // data-aos-easing="ease-in-sine"
              ></div> */}
              <div
                className="item"
                // data-aos="fade-in"
                // data-aos-offset="300"
                // data-aos-easing="ease-in-sine"
              >
                We’ve delivered organizational, board and advisory solutions for
                some of the world's most exciting VC-backed companies across the
                globe. Helping shape the future of work, life and play!
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutBanner;
