import React, { useEffect, useState } from "react";
import { gsap } from "gsap";
import Slider from "react-slick";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from "gsap/Draggable";
import { InertiaPlugin } from "gsap/InertiaPlugin";
import TeamBox from "./TeamBox";

import team1 from "../../Images/team1.png";
import team2 from "../../Images/team2.png";
import team3 from "../../Images/team3.png";
import team4 from "../../Images/team4.png";
import team5 from "../../Images/team5.png";
import team6 from "../../Images/team6.png";
// import fallVideo from "../../Images/video/fall-23881.mp4";
import teamVideo1 from "../../Images/video/team1.mp4";
import teamVideo2 from "../../Images/video/team2.mp4";
import teamVideo3 from "../../Images/video/team3.mp4";
import teamVideo4 from "../../Images/video/team4.mp4";
import teamVideo5 from "../../Images/video/team5.mp4";
import teamVideo6 from "../../Images/video/team6.mp4";

import "./style.scss";

const teamsList = [
  {
    name: "Shaun Soliman",
    designation: "Founder and Head of Strategy",
    image: team1,
    video: teamVideo1,
  },
  {
    name: "Sandy Angela",
    designation: "Brand Partner",
    image: team2,
    video: teamVideo2,
  },
  {
    name: "Omer Ali",
    designation: "Head of Delivery",
    image: team3,
    video: teamVideo3,
  },
  {
    name: "Sandra Soliman",
    designation: "Head of Customer Experience",
    image: team5,
    video: teamVideo5,
  },
  {
    name: "Shawaiz Raza",
    designation: "Talent Success Partner",
    image: team4,
    video: teamVideo4,
  },
  {
    name: "Xiao Li",
    designation: "Global Enablement Lead",
    image: team6,
    video: teamVideo6,
  },
];

const settings = {
  dots: false,
  infinite: true,
  speed: 1000,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 0,
  easing: "linear",
  touchThreshold: 100,
  variableWidth: window.innerWidth < 520 ? true : false,
  useTransform: false,
  responsive: [
    {
      breakpoint: 10000,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 3200,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 2800,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 2400,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1666,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      easing: "ease-out",
      speed: 300,
      useTransform: false,
      touchThreshold: 200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

gsap.registerPlugin(Draggable, InertiaPlugin);

const OurTeamSection = () => {
  const [swipping, setSwipping] = useState(false);
  useEffect(() => {
    if (window?.screen?.width > 500) {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".team-members-sec",
          //no need for start since default value is start: "top top" and it is already on top
          start: "-300px",
          markers: false,
        },
      });

      tl.fromTo(
        ".team-wrapper",
        {
          x: 2000,
        },
        {
          x: 0,
          duration: 0.8,
          ease: "ease",
        }
      );
    }
  }, []);

  useEffect(() => {
    // For Mobile view slider
    if (window.innerWidth < 520) {
      const boxes = gsap.utils.toArray(".team-box");
      // const prevId = document.querySelector("#prev");
      // const nextId = document.querySelector("#next");

      // console.clear();

      // let activeElement;
      const loop = horizontalLoop(boxes, {
        paused: true,
        draggable: true, // make it draggable
        center: false, // active element is the one in the center of the container rather than th left edge
        onChange: (element, index) => {
          // when the active element changes, this function gets called.
          // activeElement && activeElement.classList.remove("active");
          // element.classList.add("active");
          // activeElement = element;
        },
      });

      loop.time();

      loop.next({ duration: 5, ease: "power1.inOut" });
      loop.previous({ duration: 5, ease: "power1.inOut" });

      // nextId.addEventListener("click", () => {
      //   loop.next({ duration: 1.5, ease: "power1.inOut" });
      // });

      // prevId.addEventListener("click", () => {
      //   loop.previous({ duration: 1.5, ease: "power1.inOut" });

      // });

      // boxes.forEach((box, i) =>
      //   box.addEventListener("click", () => {
      //     loop.toIndex(i, { duration: 2, ease: "power1.inOut" });
      //   })
      // );

      function horizontalLoop(items, config) {
        items = gsap.utils.toArray(items);
        config = config || {};
        let onChange = config.onChange,
          lastIndex = 0,
          tl = gsap.timeline({
            repeat: config.repeat,
            onUpdate:
              onChange &&
              function () {
                let i = tl.closestIndex();
                if (lastIndex !== i) {
                  lastIndex = i;
                  onChange(items[i], i);
                }
              },
            paused: config.paused,
            defaults: { ease: "none", duration: 5 },
            onReverseComplete: () =>
              tl.totalTime(tl.rawTime() + tl.duration() * 100),
          }),
          length = items.length,
          startX = items[0].offsetLeft,
          times = [],
          widths = [],
          spaceBefore = [],
          xPercents = [],
          curIndex = 0,
          indexIsDirty = false,
          center = config.center,
          pixelsPerSecond = (config.speed || 1) * 100,
          snap =
            config.snap === false
              ? (v) => v
              : gsap.utils.snap(config.snap || 1), // some browsers shift by a pixel to accommodate flex layouts, so for example if width is 20% the first element's width might be 242px, and the next 243px, alternating back and forth. So we snap to 5 percentage points to make things look more natural
          timeOffset = 0,
          container =
            center === true
              ? items[0].parentNode
              : gsap.utils.toArray(center)[0] || items[0].parentNode,
          totalWidth,
          getTotalWidth = () =>
            items[length - 1].offsetLeft +
            (xPercents[length - 1] / 100) * widths[length - 1] -
            startX +
            spaceBefore[0] +
            items[length - 1].offsetWidth *
              gsap.getProperty(items[length - 1], "scaleX") +
            (parseFloat(config.paddingRight) || 0),
          populateWidths = () => {
            let b1 = container.getBoundingClientRect(),
              b2;
            items.forEach((el, i) => {
              widths[i] = parseFloat(gsap.getProperty(el, "width", "px"));
              xPercents[i] = snap(
                (parseFloat(gsap.getProperty(el, "x", "px")) / widths[i]) *
                  100 +
                  gsap.getProperty(el, "xPercent")
              );
              b2 = el.getBoundingClientRect();
              spaceBefore[i] = b2.left - (i ? b1.right : b1.left);
              b1 = b2;
            });
            gsap.set(items, {
              // convert "x" to "xPercent" to make things responsive, and populate the widths/xPercents Arrays to make lookups faster.
              xPercent: (i) => xPercents[i],
            });
            totalWidth = getTotalWidth();
          },
          timeWrap,
          populateOffsets = () => {
            timeOffset = center
              ? (tl.duration() * (container.offsetWidth / 2)) / totalWidth
              : 0;
            center &&
              times.forEach((t, i) => {
                times[i] = timeWrap(
                  tl.labels["label" + i] +
                    (tl.duration() * widths[i]) / 2 / totalWidth -
                    timeOffset
                );
              });
          },
          getClosest = (values, value, wrap) => {
            let i = values.length,
              closest = 1e10,
              index = 0,
              d;
            while (i--) {
              d = Math.abs(values[i] - value);
              if (d > wrap / 2) {
                d = wrap - d;
              }
              if (d < closest) {
                closest = d;
                index = i;
              }
            }
            return index;
          },
          populateTimeline = () => {
            let i, item, curX, distanceToStart, distanceToLoop;
            tl.clear();
            for (i = 0; i < length; i++) {
              item = items[i];
              curX = (xPercents[i] / 100) * widths[i];
              distanceToStart =
                item.offsetLeft + curX - startX + spaceBefore[0];
              distanceToLoop =
                distanceToStart + widths[i] * gsap.getProperty(item, "scaleX");
              tl.to(
                item,
                {
                  xPercent: snap(((curX - distanceToLoop) / widths[i]) * 100),
                  duration: distanceToLoop / pixelsPerSecond,
                },
                0
              )
                .fromTo(
                  item,
                  {
                    xPercent: snap(
                      ((curX - distanceToLoop + totalWidth) / widths[i]) * 100
                    ),
                  },
                  {
                    xPercent: xPercents[i],
                    duration:
                      (curX - distanceToLoop + totalWidth - curX) /
                      pixelsPerSecond,
                    immediateRender: false,
                  },
                  distanceToLoop / pixelsPerSecond
                )
                .add("label" + i, distanceToStart / pixelsPerSecond);
              times[i] = distanceToStart / pixelsPerSecond;
            }
            timeWrap = gsap.utils.wrap(0, tl.duration());
          },
          refresh = (deep) => {
            let progress = tl.progress();
            tl.progress(0, true);
            populateWidths();
            deep && populateTimeline();
            populateOffsets();
            deep && tl.draggable
              ? tl.time(times[curIndex], true)
              : tl.progress(progress, true);
          },
          proxy;
        gsap.set(items, { x: 0 });
        populateWidths();
        populateTimeline();
        populateOffsets();
        window.addEventListener("resize", () => refresh(true));
        function toIndex(index, vars) {
          vars = vars || {};
          Math.abs(index - curIndex) > length / 2 &&
            (index += index > curIndex ? -length : length); // always go in the shortest direction
          let newIndex = gsap.utils.wrap(0, length, index),
            time = times[newIndex];
          if (time > tl.time() !== (index > curIndex && index !== curIndex)) {
            // if we're wrapping the timeline's playhead, make the proper adjustments
            time += tl.duration() * (index > curIndex ? 1 : -1);
          }
          if (time < 0 || time > tl.duration()) {
            vars.modifiers = { time: timeWrap };
          }
          curIndex = newIndex;
          vars.overwrite = true;
          gsap.killTweensOf(proxy);
          return vars.duration === 0
            ? tl.time(timeWrap(time))
            : tl.tweenTo(time, vars);
        }
        tl.toIndex = (index, vars) => toIndex(index, vars);
        tl.closestIndex = (setCurrent) => {
          let index = getClosest(times, tl.time(), tl.duration());
          if (setCurrent) {
            curIndex = index;
            indexIsDirty = false;
          }
          return index;
        };
        tl.current = () => (indexIsDirty ? tl.closestIndex(true) : curIndex);
        tl.next = (vars) => toIndex(tl.current() + 2, vars);
        tl.previous = (vars) => toIndex(tl.current() - 2, vars);
        tl.times = () => 100;
        tl.progress(1, true).progress(0, true); // pre-render for performance
        if (config.reversed) {
          tl.vars.onReverseComplete();
          tl.reverse();
        }
        if (config.draggable && typeof Draggable === "function") {
          proxy = document.createElement("div");
          let wrap = gsap.utils.wrap(0, 1),
            ratio,
            startProgress,
            draggable,
            dragSnap,
            lastSnap,
            initChangeX,
            align = () =>
              tl.progress(
                wrap(startProgress + (draggable.startX - draggable.x) * ratio)
              ),
            syncIndex = () => tl.closestIndex(true);
          typeof InertiaPlugin === "undefined" &&
            console.warn(
              "InertiaPlugin required for momentum-based scrolling and snapping. https://greensock.com/club"
            );
          draggable = Draggable.create(proxy, {
            trigger: items[0].parentNode,
            type: "x",
            onPressInit() {
              let x = this.x;
              gsap.killTweensOf(tl);
              startProgress = tl.progress();
              refresh();
              ratio = 1 / totalWidth;
              initChangeX = startProgress / -ratio - x;
              gsap.set(proxy, { x: startProgress / -ratio });
            },
            onDrag: align,
            onThrowUpdate: align,
            overshootTolerance: 0,
            inertia: true,
            snap(value) {
              //note: if the user presses and releases in the middle of a throw, due to the sudden correction of proxy.x in the onPressInit(), the velocity could be very large, throwing off the snap. So sense that condition and adjust for it. We also need to set overshootTolerance to 0 to prevent the inertia from causing it to shoot past and come back
              if (Math.abs(startProgress / -ratio - this.x) < 10) {
                return lastSnap + initChangeX;
              }
              let time = -(value * ratio) * tl.duration(),
                wrappedTime = timeWrap(time),
                snapTime = times[getClosest(times, wrappedTime, tl.duration())],
                dif = snapTime - wrappedTime;
              Math.abs(dif) > tl.duration() / 2 &&
                (dif += dif < 0 ? tl.duration() : -tl.duration());
              lastSnap = (time + dif) / tl.duration() / -ratio;
              return lastSnap;
            },
            onRelease() {
              syncIndex();
              draggable.isThrowing && (indexIsDirty = true);
            },
            onThrowComplete: syncIndex,
          })[0];
          tl.draggable = draggable;
        }
        tl.closestIndex(true);
        lastIndex = curIndex;
        onChange && onChange(items[curIndex], curIndex);
        return tl;
      }
    }
  }, []);

  const onTeamDraging = () => {
    const mainTeamSection = document.querySelector("#main-team-section");

    if (mainTeamSection) {
      mainTeamSection.style.marginLeft = "0px";
    }
  };

  return (
    <>
      {/* <button id="prev">Previous</button>

      <button id="next">Next</button> */}

      {window.innerWidth < 520 ? (
        <div
          className="team-wrapper mobile-view-team-main"
          id="teamSection"
          // data-aos="fade-left"
          // data-aos-easing="ease-in-back"
          // data-aos-delay="800"
          // data-aos-duration="500"
          // onPointerMove={() => onTeamDraging()}
          onMouseDown={() => onTeamDraging()}
        >
          {teamsList.map((data, index) => (
            <TeamBox key={index} data={data} index={index} />
          ))}
        </div>
      ) : (
        <div
          className={
            window.innerWidth < 480
              ? "team-wrapper team-mobile-wrapper"
              : "team-wrapper"
          }
          style={{ marginLeft: swipping ? 0 : 32 }}
        >
          <Slider {...settings} onSwipe={() => setSwipping(true)}>
            {teamsList.map((data, index) => (
              <TeamBox key={index} data={data} index={index} />
            ))}
          </Slider>
        </div>
      )}
    </>
  );
};

export default OurTeamSection;
