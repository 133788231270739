import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./style.scss";

function Header() {
  const [position, setPosition] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [pageYHeight, setPageYHeight] = useState(0);

  useEffect(() => {
    gsap.utils.toArray(".parallax-wrapper").forEach((section, i) => {
      section.bg = section.querySelector(".parallax-bg");

      //  section.bg.style.backgroundImage = `url("https://picsum.photos/1920?random=${i}")`;

      if (i) {
        // The image starts offset 50% of the vertical height. Remember, the background-attachment is 'fixed', so the offset is from the top of the viewport, not the top of the section.
        section.bg.style.backgroundPosition = `50% ${window.innerHeight / 2}px`;

        gsap.to(section.bg, {
          backgroundPosition: `50% ${-window.innerHeight / 2}`,
          ease: "none",
          scrollTrigger: {
            trigger: section,
            scrub: true,
            // Default values:
            // start: "top bottom", // top of elem meets bottom of screen
            // end: "bottom top" // Bottom of elem meets top of screen
          },
        });
      } else {
        section.bg.style.backgroundPosition = "50% 0px";

        gsap.to(section.bg, {
          backgroundPosition: `50% ${-window.innerHeight / 2}px`,
          ease: "none",
          scrollTrigger: {
            trigger: section,
            //  start: "top top", // top of elem meets top of screen (e.g., on load)
            // end: "bottom top", // This is the default
            scrub: true,
          },
        });
      }
    });
  }, []);

  useEffect(() => {
    var mWrap = document.querySelectorAll(".magnetic-wrap");

    function parallaxIt(e, wrap, movement = 0.1) {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      var boundingRect = wrap.mArea.getBoundingClientRect();
      var halfDiff = Math.abs(boundingRect.width - boundingRect.height) / 2;
      var relX = e.pageX - boundingRect.left - halfDiff;
      var relY = e.pageY - boundingRect.top;

      gsap.to(wrap.mContent, {
        x: (relX - boundingRect.width / 2) * movement,
        y: (relY - boundingRect.height / 2 - scrollTop) * movement,
        ease: "power3",
        duration: 0.8,
      });
    }

    mWrap.forEach(function (wrap) {
      wrap.mContent = wrap.querySelector(".js-magnetic-content");
      wrap.mArea = wrap.querySelector(".js-magnetic-area");

      wrap?.mArea?.addEventListener("mousemove", function (e) {
        parallaxIt(e, wrap);
      });

      wrap?.mArea?.addEventListener("mouseleave", function (e) {
        gsap.to(wrap.mContent, {
          scale: 1,
          x: 0,
          y: 0,
          ease: "power3",
          duration: 0.8,
        });
      });
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      let moving = window.pageYOffset;
      const scrollCount = window.scrollY;

      if (scrollCount > 10) {
        setVisible(position > moving);
        setPosition(moving);
      } else {
        setVisible(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });

  useEffect(() => {
    const myElement = document.getElementById("ball-box-wrapper");
    let bounding = myElement.getBoundingClientRect();

    if (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.right <= window.innerWidth &&
      bounding.bottom <= window.innerHeight
    ) {
      console.log("Element is in the viewport!");
    } else {
      console.log("Element is NOT in the viewport!");
    }

    // document.addEventListener(
    //   "scroll",
    //   function () {
    //     if (isInViewport(footerSec)) {
    //       console.log('Footer in View Port')
    //     } else {
    //       console.log("Footer without View Port");
    //     }
    //   },
    //   {
    //     passive: true,
    //   }
    // );
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    gsap.to(".footer", {
      // x: 50,
      // duration: 3,
      scrollTrigger: {
        trigger: ".footer",
        toggleActions: "play none none reset",
        onEnter: () => hideHeader(),
        onEnterBack: () => hideHeader(),
        onLeave: () => showHeader(),
        onLeaveBack: () => showHeader(),
      },
    });
  }, []);

  // useEffect(() => {
  //   ScrollTrigger.create({
  //     trigger: ".header",
  //     start: "top top",
  //     endTrigger: "footer",
  //     end: "bottom+=100vh bottom",
  //     pin: true,
  //     pinType: "fixed",
  //     markers: false,
  //   });
  // }, []);

  const showHeader = () => {
    const element = document.querySelector(".header");
    if (element) {
      element.style.display = "block";
      element.style.opacity = 1;
    }
  };

  const hideHeader = () => {
    const element = document.querySelector(".header");
    if (element) {
      element.style.display = "none";
      element.style.opacity = 0;
    }
  };

  const header = visible ? "header" : "header hidden";
  return (
    <>
      {/* <Scroll /> */}
      <div className={header}>
        <div className="container">
          <div className="row">
            <div className="col-3 p-0">
              <a href="/">
                <div className="logo">
                  <svg
                    width="50"
                    height="28"
                    viewBox="0 0 50 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.2428 26.9231C9.81042 26.9231 7.67537 26.3905 5.8376 25.3253C3.99984 24.2334 2.56746 22.6756 1.54048 20.6517C0.513493 18.6278 0 16.1911 0 13.3417C0 10.4923 0.513493 8.08225 1.54048 6.11162C2.56746 4.11436 3.99984 2.59644 5.8376 1.55786C7.67537 0.519288 9.81042 0 12.2428 0C14.6751 0 16.8101 0.519288 18.6479 1.55786C20.4857 2.59644 21.918 4.11436 22.945 6.11162C23.972 8.08225 24.4855 10.4923 24.4855 13.3417C24.4855 16.1911 23.972 18.6278 22.945 20.6517C21.918 22.6756 20.4857 24.2334 18.6479 25.3253C16.8101 26.3905 14.6751 26.9231 12.2428 26.9231ZM12.2428 21.0112C13.2968 21.0112 14.2021 20.7049 14.9589 20.0924C15.7426 19.4533 16.3372 18.5612 16.7426 17.4161C17.148 16.271 17.3507 14.9129 17.3507 13.3417C17.3507 11.7705 17.148 10.439 16.7426 9.34718C16.3372 8.22871 15.7426 7.37655 14.9589 6.79069C14.2021 6.20482 13.2968 5.91189 12.2428 5.91189C11.1887 5.91189 10.699 6.20482 9.48611 6.79069C8.72938 7.37655 8.14832 8.22871 7.74293 9.34718C7.33754 10.439 7.13485 11.7705 7.13485 13.3417C7.13485 14.9129 7.33754 16.271 7.74293 17.4161C8.14832 18.5612 8.72938 19.4533 9.48611 20.0924C10.699 20.7049 11.1887 21.0112 12.2428 21.0112Z"
                      fill="black"
                    ></path>
                    <path
                      d="M28.1901 26.4437V0.479344H35.1628V10.1461H43.0273V0.479344H50V16.1379H43.0273H35.1628V26.4437H28.1901Z"
                      fill="black"
                    ></path>
                    <circle
                      className="circle-bounce"
                      cx="46.591"
                      cy="23.8637"
                      r="3.40909"
                      fill="#EA3335"
                    ></circle>
                  </svg>
                </div>
              </a>
            </div>
            <div className="col-9 p-0">
              <div className="header-menu">
                <ul>
                  <li className="magnetic-wrap">
                    <div className="js-magnetic-area magnetic-size"></div>
                    <div className="js-magnetic-content">
                      <a href="/about">About</a>
                    </div>
                  </li>
                  <li className="magnetic-wrap">
                    <div className="js-magnetic-area magnetic-size"></div>
                    <div className="js-magnetic-content">
                      <a href="/services">Services</a>
                    </div>
                  </li>
                  <li className="magnetic-wrap">
                    <div className="js-magnetic-area magnetic-size"></div>
                    <div className="js-magnetic-content">
                      <a href="/faqs">Faqs</a>
                    </div>
                  </li>
                  {/* <li>
                    <Link to="/about">Contact</Link>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
