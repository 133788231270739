import React, { useEffect } from "react";
import AboutBanner from "../../Components/AboutBanner";
import Header from "../../Components/Header";
import TeamMembers from "../../Components/TeamMembers";
import { gsap } from "gsap";
import TRight from "../../Components/TransformRight";
import TLeft from "../../Components/TransformLeft";
import Neck from "../../Components/Neck";
import Face from "../../Components/Face";

import "./style.scss";
import "./responsiveness.scss";

const About = () => {
  useEffect(() => {
    document.body.style.backgroundColor = "#fff";
  }, []);

  return (
    <>
      {/* <div className="background-dark">
        <Header />
      </div> */}
      <AboutBanner />
      <div className="transform-sec blue-about-bg">
        <div className="container">
          <TRight />
          <TLeft />
        </div>
      </div>
      <div className="accelerate-sec">
        <div className="container">
          <div className="title">
            <span>a</span>CCELER
            <br />
            ATE
          </div>
          <div className="des" data-aos="fade-up">
            Geared for hyper-scale, your growth solution will be in full force.
            Amplifying your hiring engines, boosting your operational fluency
            and capability - delivering unmatched performance.
          </div>
          <div
            className="make-flex"
            data-aos="fade-zoom-in"
            data-aos-easing="ease-in-back"
            data-aos-delay="300"
            data-aos-offset="0"
          >
            <Neck />
            {/* <Face /> */}
            {/* <div className="neck"></div>
            <div className="face"></div> */}
          </div>
        </div>
      </div>

      <div className="sustain-sec">
        <div className="container">
          <div className="make-flex">
            <div className="title">
              SUST
              <br />
              <span>a</span>IN
            </div>
            <div
              className="des"
              data-aos={window.innerWidth < 500 ? "fade-up" : "fade-left"}
            >
              Keep ahead of the curve. Measure your overall health and
              performance. Use data-driven insights to track, iterate and
              continuously improve - predicting your future.
            </div>
          </div>
        </div>
      </div>
      <TeamMembers />
    </>
  );
};

export default About;
