import React, { useEffect } from "react";
import Header from "../../Components/Header";
import Logo from "../../Images/calendly.png";
import { Parallax } from "react-parallax";
import A1 from "../../Images/creation_bg.jpg";
import "./style.scss";
import "./responsiveness.scss";

const insideStyles = {
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

function CreationInnerPage(props) {
  useEffect(() => {
    document.body.style.backgroundColor = "#fff";
  }, []);
  return (
    <>
      {/* <Header /> */}
      <div className="mar-top">
        <div className="container">
          <div className="page-heading">
            Cre<span>a</span>ti
            <br />
            on
          </div>
          <div className="make-flex">
            <div className="des">
              <div className="lab">
                After Calendly's acquisition of Prelude, ONE worked closely with
                Calendly's executive board to increase velocity across APAC.
                This involved a complete redesign of their talent strategy for
                the region. ONE Humun’s Augmentation Platform was used to target
                new skills and capabilities which were critical for Calendly’s
                presence in the region - the results were:
              </div>
              <div className="listing">
                <div className="item">
                  Elimination of external recruitment fees from the start of
                  engagement.
                </div>
                <div className="item">
                  Increased volume of new hires by three-fold within six months.
                </div>
                <div className="item">
                  Restructured systems and operations to gear for hyper growth.
                </div>
                <div className="item">
                  Reduced workforce costs of new hires by 21% .
                </div>
                <div className="item">
                  Increased speed to hire by 18% across all technical
                  disciplines.
                </div>
              </div>
            </div>
            <div className="parallax_bg">
              <Parallax bgImage={A1} strength={-50}>
                <div className="bg-img">
                  <div style={insideStyles}>
                    <img src={Logo} alt="" />
                  </div>
                </div>
              </Parallax>
            </div>
          </div>
          <div className="info">
            <div className="head">
              Tope <span>a</span>woton<span>a</span> - <label>CEO</label>
            </div>
            <div className="des">
              “I very rarely provide testimonials. In this instance, I felt the
              need. We were under extreme pressure to hit a number of aggressive
              milestones across APAC. The team at ONE were able to understand
              our business very quickly and implement tactical solutions that
              provided immediate impact for the region. We simply would not have
              achieved what we did without our partnership. It is for this
              reason, I would not hesitate to recommend ONE to any high growth
              business that are looking at better ways to scale”
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreationInnerPage;
