import React from "react";

function FaqArrow(props) {
  return (
    <>
      <div className="arrow">
        <svg
          width="12"
          height="9"
          viewBox="0 0 12 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_231_468)">
            <path
              d="M0.857178 1.07605L5.99575 7.07605L11.1429 1.07605"
              stroke="#202020"
              stroke-width="2"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_231_468">
              <rect
                width="12"
                height="8.17286"
                fill="white"
                transform="translate(0 0.218872)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
    </>
  );
}

export default FaqArrow;
