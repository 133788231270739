import React, { useEffect } from "react";
import Header from "../../Components/Header";
import Logo from "../../Images/augmentation.png";
import { Parallax } from "react-parallax";
import A1 from "../../Images/augmentation.jpg";
import "./style.scss";

const insideStyles = {
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

function AugmentationInnerPage(props) {
  useEffect(() => {
    document.body.style.backgroundColor = "#fff";
  }, []);
  return (
    <>
      {/* <Header /> */}
      <div className="mar-top">
        <div className="container">
          <div className="page-heading">
            <span>a</span>ugmen
            <br />t<span>a</span>tion
          </div>
          <div className="make-flex">
            <div className="des">
              <div className="lab">
                Accenture was struggling to meet their customers lead-times when
                resourcing on projects. This resulted in a significant loss of
                revenue across their Adtech & Martech practices. ONE’s
                Augmentation Platform and shared risk model bolstered directly
                onto Accenture’s delivery teams - the results were:
              </div>
              <div className="listing">
                <div className="item">
                  30% improvement on lead times when resourcing projects.
                </div>
                <div className="item">
                  14% increase in project delivery profit margins.
                </div>
                <div className="item">
                  Increased conversion of sales pipeline by 25%.
                </div>
                <div className="item">
                  AI Data-driven predictability insights when scaling on future
                  projects.
                </div>
                <div className="item">$6m in sales pipeline introduced.</div>
              </div>
            </div>
            <div className="parallax_bg">
              <Parallax bgImage={A1} strength={-50}>
                <div className="bg-img">
                  <div style={insideStyles}>
                    <img src={Logo} alt="" />
                  </div>
                </div>
              </Parallax>
            </div>
          </div>
          <div className="info">
            <div className="head">
              Kevin R<span>a</span>y - Senior Vice President, Delivery
            </div>

            <div className="des">
              “We were not getting enough traction through our normal channels
              across our MarTech and AdTech practices and needed a solution. The
              team at ONE really delivered talent we had never seen and provided
              serious firepower for the overall practice. What was most
              refreshing was their ability to collaborate and augment swiftly
              with our existing teams. They really became an extension of our
              brand. We ended up referring them to some of our other practices
              due to our significant improvements in resourcing.”
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AugmentationInnerPage;
