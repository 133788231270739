import React, { useEffect } from "react";
import TouchCircle from "../TouchCircle";
import { gsap } from "gsap";
import { MouseParallax, ScrollParallax } from "react-just-parallax";
import A1 from "../../Images/home-banner1.jpg";
import { Parallax } from "react-parallax";
import "./style.scss";

const insideStyles = {
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

function HomeBanner() {
  // useEffect(() => {
  //   gsap.utils.toArray(".parallax-wrapper").forEach((section, i) => {
  //     section.bg = section.querySelector(".parallax-bg");

  //     //  section.bg.style.backgroundImage = `url("https://picsum.photos/1920?random=${i}")`;

  //     if (i) {
  //       // The image starts offset 50% of the vertical height. Remember, the background-attachment is 'fixed', so the offset is from the top of the viewport, not the top of the section.
  //       section.bg.style.backgroundPosition = `50% ${
  //         window.innerHeight / 2
  //       }px`;

  //       gsap.to(section.bg, {
  //         backgroundPosition: `50% ${-window.innerHeight / 2}`,
  //         ease: "none",
  //         scrollTrigger: {
  //           trigger: section,
  //           scrub: true,
  //           // Default values:
  //           // start: "top bottom", // top of elem meets bottom of screen
  //           // end: "bottom top" // Bottom of elem meets top of screen
  //         },
  //       });
  //     } else {
  //       section.bg.style.backgroundPosition = "50% 0px";

  //       gsap.to(section.bg, {
  //         backgroundPosition: `50% ${-window.innerHeight / 2}px`,
  //         ease: "none",
  //         scrollTrigger: {
  //           trigger: section,
  //           //  start: "top top", // top of elem meets top of screen (e.g., on load)
  //           // end: "bottom top", // This is the default
  //           scrub: true,
  //         },
  //       });
  //     }
  //   });
  // }, []);

  return (
    <>
      <div className="home-banner" data-aos="fade-up">
        <div className="container">
          <div className="heading">
            <label>
              TR<span>a</span>NSFORM
            </label>{" "}
            YOUR GROWTH, MAKE SC<span>a</span>LING{" "}
            <label>
              E<span>a</span>SY.
            </label>
          </div>
          {/* <div className="parallex-bg"></div> */}
          <div className="bg-sec">
            <div className="m-view">
              <Parallax bgImage={A1} strength={-100}>
                <div className="bg-img">
                  <div style={insideStyles}></div>
                </div>
              </Parallax>
            </div>
            <div className="parallax-wrapper">
              <div className="parallax-bg main-banner-bg" />
            </div>
            {/* <ScrollParallax
              strength={1}
              isAbsolutelyPositioned={true}
              shouldPause
            >
              <img src={A1} alt="" />
            </ScrollParallax> */}
            {/* <div className="main-banner-bg"></div> */}
          </div>
        </div>
        {/* <div className="arrow-bottom">
          <svg
            width="116"
            height="115"
            viewBox="0 0 116 115"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="58.5"
              y1="2.18557e-08"
              x2="58.5"
              y2="114"
              stroke="black"
            />
            <path d="M1 57L58 114" stroke="black" />
            <path d="M115 57L58 114" stroke="black" />
          </svg>
        </div> */}
      </div>
      <div className="make-scaling">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="info">
                <div className="head">
                  Build dre<span>a</span>m te<span>a</span>ms, <br />build dre
                  <span>a</span>m businesses!
                  {/* <label>- simple!</label> */}
                </div>
                <div className="des">
                  ONE Humun empowers organizations to accelerate their ability
                  to grow and creates opportunities for humans that never
                  existed.
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="get-in" data-aos="zoom-in">
                <TouchCircle />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeBanner;
