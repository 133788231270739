import React, { useEffect } from "react";
import Header from "../../Components/Header";
import ServicesBanner from "../../Components/ServicesBanner";

import "./style.scss";
import "./responsiveness.scss";

function Services(props) {
  useEffect(() => {
    document.body.style.backgroundColor = "#fff";
  }, []);
  return (
    <>
      {/* <div className="background-dark">
        <Header />
      </div> */}
      <ServicesBanner />
      <div className="services-info">
        <div className="container">
          <div className="item">
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
                <div className="head" data-aos="fade-in">
                  <span>A</span>ugment<span>a</span>tion <br /> Pl
                  <span>a</span>tform
                </div>
                <div className="des">
                  Shift from reacting to growth to being prepared for growth.
                  Have your future workforce mapped out for you. Our AI powered
                  matching engine will use millions of data points to map,
                  attract, vet and engage the world’s most sought-after talent,
                  augmented to your teams. Stay ahead of any competition by
                  knowing who you're going to hire well in advance.
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="row">
              <div className="col-lg-6">
                <div className="head" data-aos="fade-in">
                  <label>ONE</label> <span>a</span>s <span>a</span> <br />{" "}
                  Service
                </div>
                <div className="des">
                  Geared for high-growth businesses to achieve sustainable
                  growth. Our advisory team will embed a tailored robust
                  strategy matched to your growth objectives. With expertise
                  across talent acquisition, global expansion, operational
                  fluency, people and performance. Our team at ONE will empower
                  you to hit your growth milestones and thrive in today’s
                  competitive market.
                </div>
              </div>
              <div className="col-lg-6"></div>
            </div>
          </div>
          <div className="item">
            <div className="row">
              <div className="col-lg-6"></div>
              <div className="col-lg-6">
                <div className="head" data-aos="fade-in">
                  <label>
                    Hum<span>a</span>n
                  </label>
                  <br /> Diversity
                </div>
                <div className="des">
                  Hard to find the right talent? Want to create a diverse
                  workforce? Don’t focus on where people live, focus on what
                  they can achieve. Our globally distributed workforce and
                  integration technology enables you to employ the world's most
                  talented humans. We’ll take care of global compliance and
                  payroll so all you’ll need to think about is who’s best for
                  you.
                </div>
              </div>
            </div>
          </div>
          <div className="item">
            <div className="row">
              <div className="col-lg-6">
                <div className="head" data-aos="fade-in">
                  <label>Executive</label> <br /> M<span>a</span>tching
                </div>
                <div className="des">
                  Revolutionize your senior executive hiring through
                  cutting-edge AI technology. We'll map out your competition,
                  segment top leaders, and engage them to your purpose. Our
                  algorithmic psychometric assessments, featuring personality
                  and cognitive ability tests provide invaluable insights,
                  helping you identify perfect fit. You'll be sure to hire the
                  right transformational leaders matched to your DNA.
                </div>
              </div>
              <div className="col-lg-6"></div>
            </div>
          </div>
        </div>
        <div className="my_circle">
          <img
            src="https://one.devrol.com/wp-content/uploads/2023/02/Ellipse-8.png"
            alt=""
          />
        </div>
        <div className="my_circle2">
          <img
            src="https://one.devrol.com/wp-content/uploads/2023/02/Ellipse-9.png"
            alt=""
          />
        </div>
        <div className="my_circle3">
          <img
            src="https://one.devrol.com/wp-content/uploads/2023/02/Ellipse-8.png"
            alt=""
          />
        </div>
      </div>
    </>
  );
}

export default Services;
